<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents gray-back">
        <div class="news_wrap">
          <div class="path">뉴스센터</div>
          <div class="news-center-wrap">
            <div class="news-box">
              <div class="news-box-title">공지사항</div>
              <ul class="news-box-list">
              <li v-for="(noti) in noticeList" :key="noti.newsCenterSeq"><a href="javascript:void(0);" @click="goNotice(noti.newsCenterSeq)"><span v-if="noti.newNews=='Y'" class="icon-new">NEW</span>{{noti.newsCenterTitle}}</a></li>
              </ul>
              <a href="javascript:void(0);" @click="pageMove('BBS002M01')" class="link-more">+ 더보기</a>
            </div>
            <div class="news-box">
              <div class="news-box-title">하이프로 뉴스</div>
              <ul class="news-box-list">
                <li v-for="(news) in newsList" :key="news.newsCenterSeq"><a href="javascript:void(0);" @click="goNews(news.newsCenterSeq)"><span v-if="news.newNews=='Y'" class="icon-new">NEW</span>{{news.newsCenterTitle}}</a></li>
              </ul>
              <a href="javascript:void(0);" @click="pageMove('BBS003M01')" class="link-more">+ 더보기</a>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    data() {
        return {
            noticeList : [],
            newsList: [],
        }
    },
    mounted() {
      this.getNewsCenterList();
    },
    methods: {
        getNewsCenterList() {
            // seq, title, reg
            this.$.httpPost('/api/main/bbs/getNewsCenterList')
                .then(res => {
                    this.noticeList = res.data.noticeList;
                    this.newsList = res.data.newsList;
                }).catch(this.$.httpErrorCommon);
        },

        goNotice(idx) {
            this.$router.push({name: 'BBS002M02', params:{newsCenterSeq : idx}});
        },

        goNews(idx) {
            this.$router.push({name: 'BBS003M02', params:{newsCenterSeq : idx}});
        },

        pageMove(name) {
          //console.log('name', name);
          this.$router.move(name);
		  },

      
    },

}
</script>